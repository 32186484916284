import React, { useState, useEffect } from 'react'
import Script from 'next/script'
import cs from 'classnames'
import { primeTrustScriptSrc } from '../../../lib/config'
import { ExclamationIcon } from '@heroicons/react/solid'

import api from 'lib/api-client'
import { ICreateCreditCardResourceResponse } from 'lib/partner/primetrust/interfaces'

// Page-level component for payments
export const RegisterCard: React.FC<{
  prev: () => void
  submit: (success: boolean) => void
}> = ({ submit }) => {
  const [, setFundsTransferMethodId] = React.useState('')

  const onCreateCardResource = (methodId: string) => {
    // TODO update user in database
    setFundsTransferMethodId(methodId)
    submit(true)
  }

  return (
    <CreateCreditCardResourceWidget
      onCreateCardResource={onCreateCardResource}
    />
  )
}

const AlertWithAccentBorder: React.FC<{ code: string }> = ({ code }) => {
  return (
    <div className='bg-yellow-50 border-l-4 border-yellow-400 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <ExclamationIcon
            className='h-5 w-5 text-yellow-400'
            aria-hidden='true'
          />
        </div>
        <div className='ml-3'>
          <p className='text-sm text-yellow-700'>
            You have a new verification code:
            <span className='underline font-bold text-yellow-700 hover:text-yellow-600'>
              {code}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export const CreateCreditCardResourceWidget: React.FC<{
  onCreateCardResource: (methodId: string) => void
}> = ({ onCreateCardResource }) => {
  const [resourceId, setResourceId] = useState('')
  const [code, setCode] = useState('')
  const [widgetReady, setWidgetReady] = useState(false)

  // Theme the widget; best we can do
  const theme = {
    background: '#ffffff', // bg-white
    primary: '#6366f1', // bg-indigo-500
    success: '#3b82f6', // bg-blue-500
    error: '#f43f5e' // bg-red-500
  }

  // Called after user creates card resource
  const onCardVerified = (_: string, fundsTransferMethodId: string) => {
    onCreateCardResource(fundsTransferMethodId)
  }

  const createResourceToken =
    async (): Promise<ICreateCreditCardResourceResponse> =>
      await api.createCardsCreditCardResource()

  const fetchCode = async (resourceId: string) => {
    return api.getCardVerification(resourceId).then((res) => {
      const { code } = res

      // There might not always be a code on fetch, as we need
      // a form of validation
      if (code) {
        setCode(code)
        return res
      }
    })
  }

  useEffect(() => {
    async function bootstrap() {
      if (window) {
        const resource = await createResourceToken()
        const resourceTokenHash = resource.data.attributes.token

        console.log('[primetrust] Resource ID:', resource.data.id)
        if (resource.data.id) {
          setResourceId(resource.data.id)
          await fetchCode(resource.data.id)
        }

        // TODO remove type cast to `any`
        const _w = window as any
        _w.primeTrustReady = function (pt) {
          pt.launchCreditCard({
            // Attributes depend on whether you want to link a card or create a contribution.
            // See code snippet samples below for each flow
            target: document.getElementsByClassName('pt-verification')[0],
            resourceTokenHash,
            theme,
            events: {
              cardVerified: onCardVerified
            }
          })
          setWidgetReady(true)
        }
      }
    }

    bootstrap()
  }, [])

  useEffect(() => {
    const id = setInterval(async () => {
      if (resourceId) {
        const res = await fetchCode(resourceId)
        if (res) clearInterval(id)
      }
    }, 2000)

    return () => clearInterval(id)
  }, [resourceId])

  return (
    <div className='pb-4'>
      <Script
        type='text/javascript'
        src='https://sandbox.bootstrapper.primetrust-cdn.com/purchaseProtection.js'
        id='071fe2143248'
        defer
      ></Script>
      <Script type='text/javascript' src={primeTrustScriptSrc} defer></Script>

      <h3 className='text-lg font-medium text-gray-600'>
        Register New Card (Credit or Debit)
      </h3>

      <h3 className='text-base font-medium text-gray-400'>
        You'll only need to do this once for each new card you have.
      </h3>

      {!widgetReady && (
        <div className='flex flex-row justify-center py-10'>
          <div
            className={cs(
              'spinner-border self-center',
              'align-middle content-center',
              'w-8 h-8',
              'border-4 text-blue-400 border-solid',
              'rounded-full animate-spin'
            )}
          ></div>
        </div>
      )}

      {code && <AlertWithAccentBorder code={code} />}

      <div className='pt-verification mt-6'></div>
    </div>
  )
}
