import React, { useEffect, useState, Fragment } from 'react'
import cs from 'classnames'
import Router from 'next/router'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { Tooltip } from '@material-tailwind/react'

import { isDev, minAmount, maxAmount } from 'lib/config'
import { Fees, PaymentMethod, TokenCurrency } from 'lib/types'
import { usePriceFeed } from 'lib/hooks/useCoinGecko'
import {
  getDefaultFeesUSD,
  getFinalDestinationTokenAmount,
  getTotalFeesRoundedUSD
} from 'lib/fees'

import api from 'lib/api-client'
import { useNetworkFee } from 'lib/hooks/useNetworkFee'
import { desktop, mobile } from 'lib/utils'

export const Purchase: React.FC<{
  savedEmail: string
  hasShownPatriotAct: boolean | undefined
  submit: (amount: number, tokenCurrency: TokenCurrency) => void
}> = ({ savedEmail, hasShownPatriotAct, submit }) => {
  const [amount, setAmount] = useState(minAmount)
  const [exchangeRate, setExchangeRate] = useState(1)
  const [canSubmit, setCanSubmit] = useState(true)
  const [disabledTerms, setDisabledTerms] = useState(true)
  const [disabledPrivacy, setDisabledPrivacy] = useState(true)
  const [name, setName] = useState('')

  useEffect(() => {
    const fetchCustomer = async () => {
      const { customer } = await api.customer()
      return customer
    }
    if (hasShownPatriotAct) {
      setDisabledTerms(false)
      setDisabledPrivacy(false)
      setName(savedEmail)
    }
    if (window) {
      fetchCustomer().then((customer) => {
        if (customer !== null) {
          setName(customer.name)
          setDisabledTerms(false)
          setDisabledPrivacy(false)
        }
      })
    }
  }, [savedEmail])

  const [tokenCurrency] = useState(TokenCurrency.aUSD)

  // Simple pass-through; business logic
  // lives in child component, not this one
  const onChangeAmount = async (amount: number, exchangeRate: number) => {
    setAmount(amount)
    setExchangeRate(exchangeRate)

    if (amount >= minAmount && amount < maxAmount) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const onSubmit = () => {
    submit(amount, tokenCurrency)
  }

  return (
    <div className='px-4'>
      <div>
        <PurchaseForm
          name={name}
          token={tokenCurrency}
          onChangeAmount={onChangeAmount}
        />
      </div>

      <div className='relative mt-4 px-4 pt-4 flex items-start'>
        <div className='flex items-center h-5'>
          <input
            id='terms'
            aria-describedby='comments-description'
            name='terms-checkbox'
            type='checkbox'
            checked={!disabledTerms}
            onChange={() => setDisabledTerms(!disabledTerms)}
            className='text-indigo-600 border-gray-300 rounded'
          />
        </div>

        <div className='ml-3 text-sm'>
          <label htmlFor='terms' className='font-medium text-gray-700'>
            I agree to the
            <a
              onClick={() => Router.push('/legal/terms-of-use')}
              className='text-indigo-600  cursor-pointer px-1 py-1 font-medium text-sm sm:rounded-md'
            >
              Terms of Use
            </a>
          </label>
        </div>
      </div>

      <div className='relative px-4 pt-4 flex items-start'>
        <div className='flex items-center h-5'>
          <input
            id='privacy'
            aria-describedby='comments-description'
            name='privacy-checkbox'
            type='checkbox'
            checked={!disabledPrivacy}
            onChange={() => setDisabledPrivacy(!disabledPrivacy)}
            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
          />
        </div>

        <div className='ml-3 text-sm'>
          <label htmlFor='privacy' className='font-medium text-gray-700'>
            I agree to the
            <a
              onClick={() => Router.push('/legal/privacy-policy')}
              className='text-indigo-600 cursor-pointer px-1 py-1 font-medium text-sm sm:rounded-md'
            >
              Privacy Policy
            </a>
          </label>
        </div>
      </div>

      <div className='flex flex-row sm:py-4 sm:px-4'>
        <button
          onClick={onSubmit}
          disabled={disabledPrivacy || disabledTerms || amount < minAmount}
          className={cs(
            'rounded-lg font-bold text-white text-lg py-4 border-0 mt-10 w-full cursor-pointer',
            canSubmit && !disabledPrivacy && !disabledTerms
              ? 'bg-indigo-500'
              : 'bg-indigo-200'
          )}
        >
          Next
        </button>
      </div>
    </div>
  )
}

const PurchaseForm: React.FC<{
  name: string
  token: TokenCurrency
  onChangeAmount: (amount: number, exchangeRate: number) => void
}> = ({ name, token, onChangeAmount }) => {
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ACH)
  const [amount, setAmount] = useState(minAmount)
  const [displayAmount, setDisplayAmount] = useState(minAmount.toString())
  const [showMin, setShowMin] = useState(false)
  const [showMax, setShowMax] = useState(false)
  const [showFees, setShowFees] = useState(false)
  const [showExchangeRate, setShowExchangeRate] = useState(false)
  const [exchangeRate, setExchangeRate] = useState<number>(1)
  const { data: priceFeedData, isLoading: priceFeedDataIsLoading } =
    usePriceFeed()
  const { data: networkFeeData, isLoading: networkFeeDataIsLoading } =
    useNetworkFee(amount, token)
  const [fees, setFees] = useState<Fees>(
    getDefaultFeesUSD(amount, paymentMethod, token)
  )

  const onFiatFocus = async () => {
    setShowFees(true)
    setShowExchangeRate(true)
  }

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const _amount = Number(e.currentTarget.valueAsNumber.toFixed(2))
    setAmount(_amount || 0)
    setDisplayAmount(formatDisplayAmount(e.currentTarget.value))
    onChangeAmount(_amount || 0, exchangeRate)
  }

  const formatDisplayAmount = (displayAmount: string) => {
    // strip leading zeros
    const _displayAmount = displayAmount.replace(/^0+/, '')
    // trim to 2 decimal places
    if (_displayAmount.includes('.')) {
      const splitAmount = _displayAmount.split('.')
      const wholeNum = splitAmount[0]
      const decimals = splitAmount[1]
      return `${wholeNum}.${decimals.slice(0, 2)}`
    } else {
      return _displayAmount
    }
  }

  React.useEffect(() => {
    if (priceFeedData && !priceFeedDataIsLoading) {
      const { aUSD } = priceFeedData
      setExchangeRate(1 / aUSD.usd)
    } else {
      console.info('Error fetching prices, using defaults')
    }

    const fees = getDefaultFeesUSD(amount, paymentMethod, token)
    if (networkFeeData && !networkFeeDataIsLoading) {
      if (networkFeeData.data) {
        fees.network = networkFeeData.data
        setFees(fees)
      }
    }

    amount < minAmount ? setShowMin(true) : setShowMin(false)
    amount > maxAmount ? setShowMax(true) : setShowMax(false)
  }, [
    priceFeedData,
    priceFeedDataIsLoading,
    paymentMethod,
    amount,
    networkFeeData,
    networkFeeDataIsLoading
  ])

  return (
    <div className='flex flex-col'>
      <div>
        <h1 className={cs('flex items-center font-bold text-xl', desktop)}>
          Choose Tokens{' '}
          {isDev && (
            <span className='ml-2 px-2 py-1 font-semibold text-xs bg-indigo-500 text-white rounded-full shadow-sm'>
              STAGING
            </span>
          )}
        </h1>

        <div className='rounded-md sm:mt-4 sm:px-4 sm:py-4 border-2 border-transparent text-lg cursor-pointer'>
          <div className='flex flex-col sm:flex-row font-lg'>
            <div className='flex-initial'>
              <label className='flex text-sm font-medium text-gray-700 text-uppercase'>
                You Buy{' '}
                <span className={cs('ml-2 font-bold', mobile)}>🇺🇸 USD</span>
              </label>

              <div className={cs('mt-1', desktop)}>
                <input
                  type='text'
                  name='fiat'
                  id='fiat'
                  value='🇺🇸 USD'
                  disabled
                  className='block w-20 text-lg font-bold border-0 rounded-md bg-gray-50 px-2'
                  placeholder='USD'
                ></input>
              </div>
            </div>

            <div className='flex-grow text-right text-2xl'>
              <div className='mt-1'>
                <input
                  step='.01'
                  inputMode='numeric'
                  type='number'
                  className='sm:pl-12 block sm:inline-block w-full sm:w-min sm:ml-2 sm:mt-5 border-0 sm:border-b sm:border-gray-500 outline-0 sm:text-right bg-gray-100 sm:bg-transparent text-indigo-700 font-medium text-3xl rounded-md sm:rounded-none focus:ring-0 focus:outline-0 focus:border-0 py-2 sm:py-1'
                  placeholder={'0.00'}
                  value={displayAmount}
                  onFocus={onFiatFocus}
                  onChange={onChange}
                  min={minAmount}
                ></input>
                {showFees && (
                  <div className='flex flex-row items-center justify-end'>
                    <Tooltip
                      content='If you are a first time customer or this is your first ACH purchase, additional fees may apply'
                      placement='left'
                      className='text-xs w-56 bg-gray-200 text-gray-400'
                    >
                      <a
                        href='#'
                        className='ml-1 flex-shrink-0 text-gray-400 hover:text-gray-500'
                      >
                        <ExclamationCircleIcon
                          className='h-4 w-4 text-gray-200'
                          aria-hidden='true'
                        />
                      </a>
                    </Tooltip>
                    <Menu
                      as='div'
                      className='relative inline-block text-left mx-1'
                    >
                      <Menu.Button className='inline-flex justify-center w-full rounded-md bg-gray-50 px-2 text-gray-300 text-xs font-bold hover:bg-gray-100 hover:text-gray-400 focus:outline-none align-middle mb-1'>
                        Pay via {PaymentMethod[paymentMethod]}
                        <ChevronDownIcon
                          className='ml-1 h-3 w-3 mt-0.5'
                          aria-hidden='true'
                        />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right absolute right-0 w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1'>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={() =>
                                    setPaymentMethod(PaymentMethod.ACH)
                                  }
                                  className={cs(
                                    active
                                      ? 'bg-gray-100 text-gray-500'
                                      : 'text-gray-300',
                                    'block px-4 py-1 text-xs font-bold'
                                  )}
                                >
                                  ACH
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={() =>
                                    setPaymentMethod(PaymentMethod.Card)
                                  }
                                  className={cs(
                                    active
                                      ? 'bg-gray-100 text-gray-500'
                                      : 'text-gray-300',
                                    'block px-4 py-1 text-xs font-bold'
                                  )}
                                >
                                  Card
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <p className={cs('mb-0.5 text-gray-300 text-xs font-bold')}>
                      Estimated{' '}
                      {getTotalFeesRoundedUSD(
                        fees || getDefaultFeesUSD(amount, paymentMethod, token)
                      ).toFixed(2)}{' '}
                      USD Fee
                    </p>
                  </div>
                )}
                {showMin && (
                  <p className={cs('text-xs font-bold text-red-500')}>
                    Minimum is {minAmount}
                  </p>
                )}
                {showMax && (
                  <p className={cs('text-xs font-bold text-red-500')}>
                    Maximum is {maxAmount}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={cs(
            'rounded-md mt-4 sm:px-4 sm:py-4 border-2 border-transparent sm:mt-4 text-lg cursor-pointer'
          )}
        >
          <div className='flex flex-col sm:flex-row font-lg'>
            <div className='flex-initial'>
              <label className='flex text-sm font-medium text-gray-700 text-uppercase'>
                You Get
                <span className={cs('ml-2 font-bold', mobile)}>aUSD</span>
              </label>

              <div className={cs('mt-1', desktop)}>
                <input
                  type='text'
                  name='cryptoCurrency'
                  id='cryptoCurrency'
                  value={TokenCurrency[token]}
                  disabled
                  className='block w-20 text-lg font-bold border-0 rounded-md bg-gray-50'
                  placeholder='aUSD'
                ></input>
              </div>
            </div>

            <div className='sm:pl-12 flex-grow text-right text-2xl'>
              <div className='mt-1'>
                <input
                  className='block sm:inline-block w-full px-2 sm:px-4 sm:w-min sm:ml-2 sm:mt-5 outline-0 sm:text-right bg-gray-100 sm:bg-transparent text-gray-900 font-medium text-3xl rounded-md sm:rounded-none focus:ring-0 focus:outline-0 focus:border-0 py-2 sm:py-1'
                  value={getFinalDestinationTokenAmount(
                    amount,
                    exchangeRate,
                    fees || getDefaultFeesUSD(amount, paymentMethod, token)
                  ).toFixed(2)}
                  readOnly
                ></input>

                {showExchangeRate && (
                  <p className='text-gray-300 text-xs font-bold'>
                    Exchange rate: {exchangeRate.toFixed(3)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
