import React, { useState, useEffect, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import cs from 'classnames'
import api from 'lib/api-client'

interface Config {
  token: string | null
  onSuccess: (publicToken: any, metadata: any) => Promise<void>
  onExit: () => void
  receivedRedirectUri?: string
}

export const PlaidComponent: React.FC<{
  savedEmail: string
  prev: () => void
  submit: (success: boolean) => void
  setAccountId: React.Dispatch<React.SetStateAction<string>>
  amount: number
}> = ({ savedEmail, submit }) => {
  const [token, setToken] = useState<string | null>(null)

  const onSuccess = useCallback(async (publicToken, metadata) => {
    await fetch('/api/ach/plaid/exchangePublicToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        publicToken,
        email: savedEmail.toLowerCase()
      })
    })
      .then(async () => {
        await api
          .createAchPlaidProcessorToken(metadata.account_id)
          .then(() => submit(true))
      })
      .catch((err) => console.log(err))
  }, [])

  const onExit = useCallback(() => {
    submit(true)
  }, [])

  // Creates a Link token
  const createLinkToken = React.useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes('?oauth_state_id=')) {
      const linkToken = localStorage.getItem('link_token')
      setToken(linkToken)
    } else {
      const response = await fetch('/api/ach/plaid/createLinkToken', {})
      const data = await response.json()
      setToken(data.link_token)
      localStorage.setItem('link_token', data.link_token)
    }
    open()
  }, [])

  let isOauth = false

  const config: Config = {
    token,
    onSuccess,
    onExit
  }

  if (window.location.href.includes('?oauth_state_id=')) {
    config.receivedRedirectUri = window.location.href
    isOauth = true
  }
  const { open, ready, exit } = usePlaidLink(config)

  useEffect(() => {
    if (token == null) {
      createLinkToken()
    }
    if (isOauth && ready) {
      open()
    }
    open()
  }, [token, isOauth, ready, open, exit])

  return (
    <div>
      <div className='flex flex-col'>
        <div className='px-4 space-y-4'>
          👋 If Plaid does not automatically open, tap button to open
          <button
            className='mt-10 rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer bg-indigo-500'
            onClick={() => open()}
          >
            Open Plaid
          </button>
        </div>
      </div>
    </div>
  )
}

export default PlaidComponent
