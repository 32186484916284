import React from 'react'
import cs from 'classnames'
import { Header } from './Header'
import { PaymentMethod } from 'lib/types'

export type FundsSource = {
  method: PaymentMethod
  description: string
  disabled: boolean
}

const fundsSource: FundsSource[] = [
  {
    method: PaymentMethod.Card,
    description: 'Debit / Credit Card',
    disabled: false
  },
  {
    method: PaymentMethod.ACH,
    description: 'ACH',
    disabled: false
  }
]

export const ChooseExternalFundsSource: React.FC<{
  prev: () => void
  submit: (method: PaymentMethod) => void
}> = ({ prev, submit }) => {
  return (
    <div className='overflow-hidden rounded-md'>
      <div className='px-4 space-y-4'>
        <Header name='Choose Payment Method' step={5} total={6} prev={prev} />

        <ul role='list'>
          {fundsSource.map(({ method, description, disabled }) => (
            <li
              key={method}
              className='px-6 py-4'
              onClick={() => !disabled && submit(method)}
            >
              <div
                className={cs(
                  !disabled && 'cursor-pointer',
                  'px-3 py-4 rounded-lg text-center text-white',
                  disabled ? 'bg-indigo-300' : 'bg-indigo-500'
                )}
              >
                <h2 className={cs('text-lg font-bold')}>{description}</h2>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
