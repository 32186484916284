import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import cs from 'classnames'

import { CreditCardIcon } from '@heroicons/react/solid'

import { Header } from '../Header'
import { RegisterCard } from './RegisterCard'
import api from 'lib/api-client'
import { Card, CardType } from 'lib/types'

// Page-level component for payments
export const ChooseCard: React.FC<{
  prev: () => void
  submit: (fundTransferMethodId: string) => void
}> = ({ prev, submit }) => {
  const [createCard, setCreateCard] = useState(false)
  const [availableCards, setAvailableCards] = useState([] as Card[])
  const [isLoading, setLoading] = useState(true)

  const doneCreatingCard = (success: boolean) => {
    if (success) {
      setCreateCard(false)
    }
  }

  const getCardLogo = (cardType: CardType) => {
    if (cardType === CardType.VISA) {
      return <Image src='/visa_logo.svg' width={50} height={50} />
    } else if (cardType === CardType.MASTERCARD) {
      return <Image src='/mastercard_logo.svg' width={50} height={50} />
    } else {
      return (
        <CreditCardIcon className='h-11 w-11 align-middle' aria-hidden='true' />
      )
    }
  }

  useEffect(() => {
    async function getAvailableCards() {
      const cards = await api.getCardsFundsTransferMethods()
      setAvailableCards(cards)
      setLoading(false)
    }
    getAvailableCards()
  }, [createCard])

  const render = () => {
    if (createCard) {
      return (
        <>
          <RegisterCard
            prev={() => {
              setCreateCard(false)
            }}
            submit={doneCreatingCard}
          />
        </>
      )
    } else {
      return (
        <div>
          {isLoading ? (
            <div className='flex flex-col justify-center space-y-6 py-10'>
              <div className='text-center font-md text-indigo-700'>
                Fetching existing cards
              </div>
              <div
                className={cs(
                  'spinner-border self-center',
                  'align-middle content-center',
                  'w-10 h-10',
                  'border-4 text-indigo-700 border-solid',
                  'rounded-full animate-spin'
                )}
              />
            </div>
          ) : (
            <ul role='list'>
              {availableCards.length === 0 && (
                <div className='text-gray-700 pb-12'>
                  <h3 className='text-xl leading-6 font-medium text-gray-900'>
                    Add a Card
                  </h3>
                  <p className='mt-1 text-md text-gray-500'>
                    You don't seem to have any registered cards with us. Add one
                    below 👇
                  </p>
                </div>
              )}

              {availableCards.map(
                ({
                  cardType,
                  expirationDate,
                  lastFourDigits,
                  fundTransferMethodId
                }) => (
                  <li
                    key={fundTransferMethodId}
                    className='px-16 py-4'
                    onClick={() => submit(fundTransferMethodId)}
                  >
                    <div
                      className={cs(
                        'cursor-pointer px-3 py-4 rounded-lg text-black bg-white border-2 focus:shadow-outline hover:bg-gray-100'
                      )}
                    >
                      <div className='flex flex-row justify-between mx-2'>
                        {getCardLogo(cardType)}
                        <div className='flex flex-col pl-6'>
                          <div className='text-lg font-bold'>
                            **** {lastFourDigits}
                          </div>
                          <div className='text-sm text-gray-400 text-right'>
                            {expirationDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}
          <div className='w-full flex flex-col items-center py-4'>
            <button
              id='createCard'
              type='button'
              onClick={() => setCreateCard(true)}
              className={cs(
                'w-12 h-12 text-2xl text-white transition-colors duration-500 bg-indigo-500 rounded-full focus:shadow-outline',
                availableCards.length === 0 && !isLoading && 'animate-bounce'
              )}
            >
              +
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className='overflow-hidden rounded-md'>
      <div className='space-y-4'>
        <Header name='Select card' step={5} total={6} prev={prev} />
        <div className='px-4'>{render()}</div>
      </div>
    </div>
  )
}

export default ChooseCard
