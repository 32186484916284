import { TokenCurrency } from 'lib/types'
import useSWR from 'swr'

const fetcher = (amount, tokenCurrency, url) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ amount: amount, tokenCurrency: tokenCurrency })
  }).then((res) => res.json())

export function useNetworkFee(amount: number, tokenCurrency: TokenCurrency) {
  const { data, isValidating, error } = useSWR(
    [amount, tokenCurrency, `/api/getNetworkFees`],
    fetcher,
    {
      refreshInterval: 10000,
      revalidateIfStale: true
    }
  )

  return {
    data,
    isLoading: isValidating,
    error
  }
}
