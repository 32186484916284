import { Types } from 'ably'

import Ably from 'ably/promises'
import { useEffect } from 'react'

export function getAblyConn(): Types.RealtimePromise {
  const ably = new Ably.Realtime.Promise({
    authUrl: '/api/createTokenRequest'
  })
  return ably
}

export function useChannel(
  channelName: string,
  callbackOnMessage: (msg: Types.Message) => void
) {
  const ably = getAblyConn()
  const channel = ably.channels.get(channelName)

  const onMount = () => {
    channel.subscribe((msg) => {
      callbackOnMessage(msg)
    })
  }

  const onUnmount = () => {
    channel.unsubscribe()
  }

  const useEffectHook = () => {
    onMount()
    return () => {
      onUnmount()
    }
  }

  useEffect(useEffectHook)

  return [channel, ably]
}
