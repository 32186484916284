import { Customer } from '@prisma/client'

import * as SentryTypes from '@sentry/types'

const DOLLARS_TO_CENTS = 100

export const desktop = 'hidden sm:flex'

export const mobile = 'flex sm:hidden'

export function dollarsToCents(dollars: number) {
  return Number((dollars * DOLLARS_TO_CENTS).toFixed(0))
}

export function centsToDollars(cents: number) {
  return cents / DOLLARS_TO_CENTS
}

export function formDateToString(date: Date) {
  return date.toISOString().split('T')[0]
}

export function formDataToObject(formData: FormData) {
  const obj = {}
  for (const [key, value] of formData.entries()) {
    obj[key] = value
  }
  return obj
}

export function getShortAddress(address: string) {
  return address.length > 12
    ? address.slice(0, 6) + '...' + address.slice(-4)
    : address
}

export function waitSleep(milliseconds: number) {
  if (milliseconds < 0) throw new Error('Negative sleep time')
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export function strOrFirstOf(field: string | string[]): string {
  if (typeof field === 'undefined' || field === null) return ''
  return typeof field === 'string' ? field : field[0]
}

export function customerToSentryUser(customer: Customer): SentryTypes.User {
  const { id, email } = customer
  return {
    id: id.toString(),
    email
  }
}
