import React from 'react'
import NextHead from 'next/head'

export const Head: React.FC<{
  title?: string
  domain?: string
  twitter?: string
  description?: string
  socialImage?: string
}> = ({
  title = 'Supercharge',
  domain = 'supercharge.finance',
  twitter = 'supercharge.finance',
  description = 'A multi-chain on-ramp for the Polkadot ecosystem',
  socialImage = null
}) => {
  return (
    <NextHead>
      {/* For TailwindCSS to render responsive correctly */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>

      <meta name='twitter:title' content={title} />
      <meta property='og:title' content={title} />
      <meta property='og:site_name' content={title} />

      <meta property='twitter:domain' content={domain} />
      <meta name='twitter:creator' content={`@${twitter}`} />

      <meta name='description' content={description} />
      <meta property='og:description' content={description} />
      <meta name='twitter:description' content={description} />

      {socialImage ? (
        <>
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image' content={socialImage} />
          <meta property='og:image' content={socialImage} />
        </>
      ) : (
        <meta name='twitter:card' content='summary' />
      )}

      <title>{title}</title>
    </NextHead>
  )
}
