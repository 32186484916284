import React, { useState, useEffect } from 'react'
import Script from 'next/script'
import * as Sentry from '@sentry/nextjs'

import { Header } from './Header'

import api from 'lib/api-client'

import {
  Fees,
  OrderEventFulfilledBuy,
  PaymentMethod,
  TokenCurrency
} from 'lib/types'
import { useCustomer } from 'lib/hooks/useCustomer'
import { customerToSentryUser } from 'lib/utils'
import {
  primeTrustScriptSrc,
  primeTrustPurchaseProtectionScriptSrc,
  primeTrustPurchaseProtectionId,
  supportEmail
} from 'lib/config'
import { OrderDetails } from './OrderDetails'
import { getDefaultFeesUSD } from 'lib/fees'
import { usePriceFeed } from 'lib/hooks/useCoinGecko'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useNetworkFee } from 'lib/hooks/useNetworkFee'

export const Confirm: React.FC<{
  prev: () => void
  submit: (orderEvent: OrderEventFulfilledBuy) => void
  amount: number
  paymentMethod: PaymentMethod
  walletAddress: string
  tokenCurrency: TokenCurrency
  existingFundsTransferMethodId?: string
}> = ({
  prev,
  paymentMethod,
  submit,
  amount,
  walletAddress,
  tokenCurrency,
  existingFundsTransferMethodId = ''
}) => {
  const [showCardPaymentWidget, setShowCardPaymentWidget] = useState(false)
  const [fundsTransferMethodId] = useState(existingFundsTransferMethodId)
  const [contributionToken, setContributionToken] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [transferringFunds, setTransferringFunds] = useState(false)
  const { data: priceFeedData, isLoading: priceFeedDataIsLoading } =
    usePriceFeed()
  const { data: networkFeeData, isLoading: networkFeeDataIsLoading } =
    useNetworkFee(amount, tokenCurrency)
  const [fees, setFees] = useState<Fees>(
    getDefaultFeesUSD(amount, paymentMethod, tokenCurrency)
  )
  const [exchangeRate, setExchangeRate] = useState<number>(NaN)

  async function getFees(
    amount: number,
    paymentMethod: PaymentMethod,
    tokenCurrency: TokenCurrency
  ) {
    try {
      const feesRes = await api.getFees(
        amount,
        paymentMethod,
        tokenCurrency,
        true
      )
      if (!feesRes.success) {
        return getDefaultFeesUSD(amount, paymentMethod, tokenCurrency)
      } else {
        return feesRes.data as Fees
      }
    } catch (e) {
      return getDefaultFeesUSD(amount, paymentMethod, tokenCurrency)
    }
  }

  const onConfirm = async () => {
    setLoading(true)
    if (paymentMethod === PaymentMethod.ACH) {
      setTransferringFunds(true)
      try {
        await api
          .createAchOrder(
            amount,
            walletAddress,
            fundsTransferMethodId,
            tokenCurrency,
            fees,
            exchangeRate
          )
          .then(async (res) => {
            setTransferringFunds(false)
            setLoading(false)
            submit(res.data)
          })
      } catch (error) {
        Sentry.captureException(error)
        setError('Error making ACH purchase')
        setTransferringFunds(false)
        setLoading(false)
      }
    }

    if (paymentMethod === PaymentMethod.Card) {
      const pendingContribution = await api.createCardsPendingContribution(
        fundsTransferMethodId,
        amount
      )
      setContributionToken(
        pendingContribution.data.attributes['resource-token-hash']
      )
      setShowCardPaymentWidget(true)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (priceFeedData && !priceFeedDataIsLoading) {
      const { aUSD } = priceFeedData
      setExchangeRate(1 / aUSD.usd)
    } else {
      console.info('Error fetching prices, using defaults')
    }

    async function loadFees() {
      const fees = await getFees(amount, paymentMethod, tokenCurrency)
      setFees(fees)
      setLoading(false)
    }

    loadFees()
  }, [])

  useEffect(() => {
    if (priceFeedData && !priceFeedDataIsLoading) {
      const { aUSD } = priceFeedData
      setExchangeRate(1 / aUSD.usd)
    } else {
      console.info('Error fetching prices, using defaults')
    }

    if (networkFeeData && !networkFeeDataIsLoading) {
      if (networkFeeData.data) {
        fees.network = networkFeeData.data
        setFees(fees)
      }
    }
  }, [
    priceFeedData,
    priceFeedDataIsLoading,
    networkFeeData,
    networkFeeDataIsLoading
  ])

  // For Card Flow
  const onCardContributionFunded = async (contributionId: string) => {
    setTransferringFunds(true)
    setShowCardPaymentWidget(false)
    try {
      await api
        .createCardsOrder(
          contributionId,
          walletAddress,
          tokenCurrency,
          fees,
          exchangeRate
        )
        .then(async (res) => {
          setTransferringFunds(false)
          submit(res.data)
        })
    } catch (error) {
      Sentry.captureException(error)
      setError('Error making Card purchase')
      setTransferringFunds(false)
    }
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className='space-y-6'>
          <div className='px-2 sm:pt-4 sm:rounded-lg sm:p-6'>
            <Script
              type='text/javascript'
              src={primeTrustPurchaseProtectionScriptSrc}
              id={primeTrustPurchaseProtectionId}
              defer
            ></Script>
            <Script
              type='text/javascript'
              src={primeTrustScriptSrc}
              defer
            ></Script>
            <div className='px-4 space-y-4'>
              <Header
                name={`Order Confirmation`}
                step={6}
                total={6}
                prev={prev}
              />

              {showCardPaymentWidget ? (
                <CardPaymentWidget
                  amount={amount}
                  contributionToken={contributionToken}
                  onContributionFunded={onCardContributionFunded}
                />
              ) : (
                <>
                  {error && (
                    <div className='relative space-y-5'>
                      <div className='flex flex-row justify-center pt-5'>
                        <ExclamationCircleIcon className='h-24 w-24 text-red-600'></ExclamationCircleIcon>
                      </div>
                      <div className='px-4 sm:rounded-lg'>
                        <p className='text-sm text-gray-500'>
                          Uh oh! Something went wrong while trying to place your
                          order. Please try again or reach out to us at{' '}
                          {supportEmail}.
                        </p>

                        <br />
                      </div>
                      <div className='w-full'>
                        <button
                          disabled={loading}
                          type='submit'
                          onClick={() => location.reload()}
                          className='rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer bg-indigo-500'
                        >
                          Restart purchase
                        </button>
                      </div>
                    </div>
                  )}
                  {transferringFunds && !error && (
                    <div className='flex flex-col justify-center space-y-6 py-10 items-center'>
                      <div className='spinner-border self-center align-middle content-center w-10 h-10 border-4 text-indigo-700 border-solid rounded-full animate-spin'></div>
                      <div>
                        <h2 className='text-gray-600 text-md mt-4'>
                          Transferring funds....
                        </h2>
                      </div>
                    </div>
                  )}
                  {loading && !transferringFunds && !error && (
                    <div className='flex flex-col justify-center space-y-6 py-10 items-center'>
                      <div className='spinner-border self-center align-middle content-center w-10 h-10 border-4 text-indigo-700 border-solid rounded-full animate-spin'></div>
                    </div>
                  )}
                  {!transferringFunds && !loading && !error && (
                    <div>
                      <div className='mt-8'>
                        <OrderDetails
                          amount={amount}
                          fees={fees}
                          paymentMethod={paymentMethod}
                          exchangeRate={exchangeRate}
                        ></OrderDetails>
                      </div>
                      <div className='w-full'>
                        <div className='space-y-12 pt-12'>
                          <button
                            disabled={loading}
                            type='submit'
                            onClick={() => onConfirm()}
                            className='mt-2 rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer bg-indigo-500'
                          >
                            Place Order
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CardPaymentWidget: React.FC<{
  amount: number
  contributionToken: string
  onContributionFunded: (contributionId: string) => void
}> = ({ contributionToken, onContributionFunded }) => {
  const { data, isLoading } = useCustomer()

  const setSentryUserContext = React.useCallback(() => {
    if (data && !isLoading) {
      const { customer } = data
      if (customer) {
        Sentry.configureScope((scope) => {
          scope.setUser(customerToSentryUser(customer))
        })
      }
    }
  }, [data, isLoading])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onContribution = (contributionId, fundsTransferMethodId) => {
    onContributionFunded(contributionId)
    setSentryUserContext()
    Sentry.setExtras({
      contributionId: contributionId,
      fundsTransferMethodId: fundsTransferMethodId
    })
    Sentry.captureEvent({
      message: `Credit Card Widget Callback: Contribution Funded`,
      level: 'info'
    })
  }

  const onContributionError = (error) => {
    setSentryUserContext()
    Sentry.captureException(new Error(error))
  }

  React.useEffect(() => {
    async function bootstrap() {
      if (window) {
        // TODO remove type cast to `any`
        const _w = window as any
        _w.primeTrustReady = function (pt) {
          pt.launchCreditCard({
            target: document.getElementsByClassName('pt-contribution')[0],
            resourceTokenHash: contributionToken,
            hideAmount: true, // this hides the contribution amount on the CVV form
            events: {
              onContribution,
              onContributionError
            }
          })
        }
      }
    }

    bootstrap()
  }, [])

  return (
    <div className='px-6 py-4'>
      <Script
        type='text/javascript'
        src={primeTrustPurchaseProtectionScriptSrc}
        id={primeTrustPurchaseProtectionId}
        defer
      ></Script>
      <Script type='text/javascript' src={primeTrustScriptSrc} defer></Script>

      <div className='pt-contribution mt-6'></div>
    </div>
  )
}
