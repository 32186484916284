import useSWR from 'swr'
import api from 'lib/api-client'

export function useCustomer() {
  const { data, error } = useSWR('/api/customer', async () => api.customer())

  return {
    data,
    isLoading: !error && !data,
    error: error
  }
}
