import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { EmptyProps } from 'lib/types'

export const Footer: React.FC<EmptyProps> = () => {
  return (
    <footer className='w-full y-10 relative'>
      <div className='px-10 pb-4 items-center justify-between space-x-2'>
        <div className='space-y-6'>
          <div>
            <div className='flex flex-row justify-center items-center space-x-2'>
              <div className='text-2xl font-bold text-indigo-900'>
                Partners with
              </div>

              {/*
                pt-2 & flex items-center of parent container combine to 
                vertically align text & logo along their center lines
              */}
              <div className='pt-2 text-2xl'>
                <Image src='/acala_logo.svg' width={40} height={40} />
              </div>
            </div>
          </div>

          <div className='text-center'>
            <Link href='/legal/privacy-policy'>
              <a
                className='text-gray-500  cursor-pointer hover:text-gray-700 px-3 font-medium text-sm rounded-md'
                aria-current='page'
              >
                {' '}
                Privacy Policy{' '}
              </a>
            </Link>

            <Link href='/legal/terms-of-use'>
              <a className='text-gray-500  cursor-pointer hover:text-gray-700 px-3 font-medium text-sm rounded-md'>
                {' '}
                Terms of Use{' '}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
