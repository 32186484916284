import React from 'react'
import { Magic } from 'magic-sdk'

import { Header } from './Header'

const Form = ({ errorMessage, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <div className='pb-4'>
      <h2 className='block text-lg font-medium text-gray-700 text-uppercase w-full'>
        Enter your email
      </h2>

      <h3 className='block text-md text-gray-400 w-full'>
        We'll send an email with a one-time link to log in.
      </h3>
    </div>

    <input
      type='email'
      name='email'
      placeholder='john.doe@gmail.com'
      required
      className='appearance-none font-medium block w-full px-3 py-3 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'
    />

    <div className='mt-8 px-6 py-4 rounded-md text-sm font-base text-gray-500  bg-blue-50'>
      👋 <span className='text-base font-bold'>KYC Required</span>
      <br />
      <p className='pl-4 px-4 py-2'>
        If this is your first time using Supercharge, you will need to go
        through a one-time verification process.
        <br />
        <br />
        Verification is required by law and handled securely through a third
        party. The process takes about 5 minutes and requires a
        government-issued ID (e.g. driver's license).
      </p>
    </div>

    <div className='submit pt-10'>
      <button
        type='submit'
        className='rounded-lg font-bold text-white text-lg sm: px-4 py-4 border-0 w-full cursor-pointer bg-indigo-500'
      >
        Send Magic Link
      </button>
    </div>

    {errorMessage && <p className='error'>{errorMessage}</p>}
  </form>
)

export const MagicLogin: React.FC<{
  prev: () => void
  submit: (email: string) => void
}> = ({ prev, submit }) => {
  const [errorMsg, setErrorMsg] = React.useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (errorMsg) setErrorMsg('')

    const body = {
      email: e.currentTarget.email.value
    }

    try {
      const magicPublishableKey = process.env
        .NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY as string

      const magic = new Magic(magicPublishableKey)
      const didToken = await magic.auth.loginWithMagicLink({
        email: body.email
      })

      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken
        },
        body: JSON.stringify(body)
      })
      if (res.status === 200) {
        submit(body.email)
      } else {
        throw new Error(await res.text())
      }
    } catch (error) {
      console.error('An unexpected error happened occurred:', error)
      setErrorMsg((error as any).message)
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='px-4 space-y-4'>
        <Header name='Login' step={2} total={6} prev={prev} />

        <div className='px-2 sm:pt-4 sm:rounded-lg sm:p-6'>
          <Form errorMessage={errorMsg} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
}
