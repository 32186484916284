import { PaymentMethod } from 'lib/types'
import React from 'react'
import ChooseCard from './ChooseCard'
import ChooseAch from './ChooseAch'

// Page-level component for payments
export const ExternalFundsSources: React.FC<{
  savedEmail: string
  prev: () => void
  submit: (fundTransferMethodId: string) => void
  amount: number
  paymentMethod: PaymentMethod
  setAccountId: React.Dispatch<React.SetStateAction<string>>
}> = ({ savedEmail, prev, submit, amount, paymentMethod, setAccountId }) => {
  return (
    <div className='flex flex-col'>
      <div className='px-4 space-y-4'>
        {paymentMethod === PaymentMethod.ACH && (
          <ChooseAch
            savedEmail={savedEmail}
            prev={prev}
            submit={submit}
            setAccountId={setAccountId}
            amount={amount}
          />
        )}

        {paymentMethod === PaymentMethod.Card && (
          <ChooseCard prev={prev} submit={submit} />
        )}
      </div>
    </div>
  )
}
export default ExternalFundsSources
