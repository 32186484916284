import React from 'react'
import cs from 'classnames'

import { ChevronLeftIcon } from '@heroicons/react/solid'

export const Header: React.FC<{
  name: string
  step: number | null
  total: number | null
  prev?: (e?: any) => void
  defocus?: boolean
}> = ({ name, step, total, prev, defocus }) => {
  return (
    <div className='flex flex-row items-center justify-between'>
      {step && total && (
        <button
          onClick={prev}
          className={cs(
            'inline-flex items-center p-1.5 border border-transparent rounded-full',
            defocus && 'text-white',
            prev ? 'visible' : 'invisible'
          )}
        >
          <ChevronLeftIcon className='h-6 w-6' />
        </button>
      )}
      <h1 className={cs('text-xl font-bold', defocus && 'text-gray-400')}>
        {name}
      </h1>
      {step && total ? (
        <div className='text-md font-medium text-gray-500 pr-6'>{`${step} / ${total}`}</div>
      ) : (
        <div className='text-md font-medium text-gray-500 px-5'></div>
      )}
    </div>
  )
}
