import React, { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { Fees, PaymentMethod } from 'lib/types'
import {
  getFinalDestinationTokenAmount,
  getTotalAccountSetupFeesRoundedUSD,
  getTotalOnrampFeesRoundedUSD
} from 'lib/fees'

export const OrderDetails: React.FC<{
  amount: number
  fees: Fees
  paymentMethod: PaymentMethod
  exchangeRate: number
}> = ({ amount, fees, paymentMethod, exchangeRate }) => {
  const [showOnrampFeeBreakdown, setShowOnrampFeeBreakdown] = useState(false)
  const [showAccountFeeBreakdown, setShowAccountFeeBreakdown] = useState(false)
  const [sourceCurrency, setSourceCurrency] = useState('USD')
  const [tokenCurrency, setTokenCurrency] = useState('aUSD')
  const [feesCurrency, setFeesCurrency] = useState('USD')

  const tokenAmount = getFinalDestinationTokenAmount(amount, exchangeRate, fees)

  const totalAccountSetupFees = getTotalAccountSetupFeesRoundedUSD(fees)
  const totalOnrampFees = getTotalOnrampFeesRoundedUSD(fees)

  return (
    <section
      aria-labelledby='summary-heading'
      className='mt-2 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5'
    >
      <dl className='space-y-4'>
        <div className='flex items-center justify-between'>
          <dt className='text-lg font-medium text-gray-900'>You pay</dt>
          <dd className='text-lg font-bold text-gray-900'>
            {amount.toFixed(2)} {sourceCurrency}
          </dd>
        </div>

        <div className='flex items-center justify-between'>
          <dt className='flex text-sm text-gray-600'>
            <span>On-ramp fees</span>
            {!showOnrampFeeBreakdown && (
              <a className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                <ChevronDownIcon
                  className='h-4 w-4'
                  aria-hidden='true'
                  onClick={() =>
                    setShowOnrampFeeBreakdown(!showOnrampFeeBreakdown)
                  }
                />
              </a>
            )}
            {showOnrampFeeBreakdown && (
              <a className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                <ChevronUpIcon
                  className='h-4 w-4'
                  aria-hidden='true'
                  onClick={() =>
                    setShowOnrampFeeBreakdown(!showOnrampFeeBreakdown)
                  }
                />
              </a>
            )}
          </dt>
          <dd className='text-sm font-medium text-gray-900'>
            {totalOnrampFees.toFixed(2)} {feesCurrency}
          </dd>
        </div>

        {showOnrampFeeBreakdown && (
          <div className='space-y-4'>
            <div className='pl-4 flex items-center justify-between text-xs'>
              <dt className='text-gray-600'>
                {PaymentMethod[paymentMethod]} fees
              </dt>
              <dd className='font-medium text-gray-900'>
                {fees.onRamp.toFixed(2)} {feesCurrency}
              </dd>
            </div>
            <div className='pl-4 flex items-center justify-between text-xs'>
              <dt className='text-gray-600'>Network fees </dt>
              <dd className='font-medium text-gray-900'>
                {fees.network.toFixed(4)} {feesCurrency}
              </dd>
            </div>
          </div>
        )}
        {totalAccountSetupFees > 0 && (
          <div className='flex items-center justify-between'>
            <dt className='flex text-sm text-gray-600'>
              <span>Account setup fees (ONE-TIME ONLY)</span>
              {!showAccountFeeBreakdown && (
                <a className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                  <ChevronDownIcon
                    className='h-4 w-4'
                    aria-hidden='true'
                    onClick={() =>
                      setShowAccountFeeBreakdown(!showAccountFeeBreakdown)
                    }
                  />
                </a>
              )}
              {showAccountFeeBreakdown && (
                <a className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                  <ChevronUpIcon
                    className='h-4 w-4'
                    aria-hidden='true'
                    onClick={() =>
                      setShowAccountFeeBreakdown(!showAccountFeeBreakdown)
                    }
                  />
                </a>
              )}
            </dt>
            <dd className='text-sm font-medium text-gray-900'>
              {totalAccountSetupFees.toFixed(2)} {feesCurrency}
            </dd>
          </div>
        )}

        {totalAccountSetupFees > 0 && showAccountFeeBreakdown && (
          <div className='space-y-4'>
            {fees.accountSetup.firstTimeVerification > 0 && (
              <div className='pl-4 flex items-center justify-between text-xs'>
                <dt className='text-gray-600'>
                  KYC verification (Charged by 3rd Party)
                </dt>
                <dd className='font-medium text-gray-900'>
                  {fees.accountSetup.firstTimeVerification.toFixed(2)}{' '}
                  {feesCurrency}
                </dd>
              </div>
            )}
            {fees.accountSetup.firstTimeACH > 0 && (
              <div className='pl-4 flex items-center justify-between text-xs'>
                <dt className='text-gray-600'>
                  ACH setup (Charged by 3rd Party)
                </dt>
                <dd className='font-medium text-gray-900'>
                  {fees.accountSetup.firstTimeACH.toFixed(2)} {feesCurrency}
                </dd>
              </div>
            )}
            {fees.accountSetup.firstTimeCard > 0 && (
              <div className='pl-4 flex items-center justify-between text-xs'>
                <dt className='text-gray-600'>
                  Card setup (Charged by 3rd Party)
                </dt>
                <dd className='font-medium text-gray-900'>
                  {fees.accountSetup.firstTimeCard.toFixed(2)} {feesCurrency}
                </dd>
              </div>
            )}
          </div>
        )}

        <div className='flex items-center justify-between'>
          <dt className='text-sm text-gray-600'>Exchange rate </dt>
          <dd className='text-sm font-medium text-blue-800'>
            {exchangeRate.toFixed(4)}
          </dd>
        </div>

        <div className='border-t border-gray-200 pt-4 flex items-center justify-between'>
          <dt className='text-lg font-medium text-gray-900'>You get</dt>
          <dd className='text-lg font-semibold text-gray-900'>
            {tokenAmount.toFixed(2)} {tokenCurrency}
          </dd>
        </div>
      </dl>
    </section>
  )
}
