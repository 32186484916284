import React from 'react'
import cs from 'classnames'
import { Header } from '../Header'

import { useKYCAccountData } from 'lib/hooks/useKYCAccountData'

import {
  getKycRequiredActions,
  isAccountClosed,
  isAccountOpen,
  isKycVerified
} from 'lib/utils/primetrust'

import CheckIcon from '@heroicons/react/solid/CheckIcon'
import { BanIcon } from '@heroicons/react/solid'

import { EmptyProps } from 'lib/types'
import { supportEmail } from 'lib/config'

export const Spinner: React.FC<EmptyProps> = () => (
  <div
    className={cs(
      'spinner-border self-center',
      'align-middle content-center',
      'w-16 h-16',
      'border-4 text-blue-400 border-solid',
      'rounded-full animate-spin'
    )}
  ></div>
)

export const Verification: React.FC<{
  accountId: string
  prev: (e) => void
  submit: () => void
}> = ({ accountId, prev, submit }) => {
  const { data, isLoading, error } = useKYCAccountData(accountId)

  const renderErrors = (errors: string[]) => {
    return errors.map((err, i) => {
      return (
        <li
          className={cs(
            'mx-4 mt-4 text-sm px-4 py-4 border-2 rounded-sm',
            err.includes('pend')
              ? 'border-gray-500 bg-gray-50 text-gray-600'
              : 'border-red-500 bg-red-50 text-red-600'
          )}
          key={i}
        >
          {err}
        </li>
      )
    })
  }

  const renderKYCAccountStatus = () => {
    // this should never happen; however,
    // due to lack of typing, we handle the
    // never case
    if (!data?.account) {
      return renderLoading()
    }

    // Opened; move on
    if (isAccountOpen(data)) {
      return (
        <>
          <div className='relative space-y-12 pt-12'>
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
              <CheckIcon
                className='h-6 w-6 text-green-600'
                aria-hidden='true'
              />
            </div>

            <h4 className='text-center text-lg leading-8 font-medium tracking-tight text-gray-900 sm:text-xl'>
              Your account has been opened
            </h4>
          </div>

          <div className='w-full px-4'>
            <button
              type='submit'
              onClick={() => submit()}
              className='mt-10 rounded-lg font-bold text-white text-lg px-2 py-4 border-0 w-full cursor-pointer bg-indigo-500'
            >
              Continue to Buy aUSD
            </button>
          </div>
        </>
      )
    }

    if (isAccountClosed(data)) {
      return (
        <>
          <div className='relative space-y-12 pt-12'>
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-50'>
              <BanIcon className='h-6 w-6 text-gray-600' aria-hidden='true' />
            </div>

            <h3 className='text-center text-lg leading-8 px-12 font-medium tracking-tight text-gray-700 sm:text-xl'>
              Your account has unfortunately been closed due to checks made
              during KYC by our banking partner.
            </h3>

            <h4 className='text-center text-md text-gray-300 px-4 sm:text-md'>
              Please reach out to us at {supportEmail}
            </h4>
          </div>
        </>
      )
    }

    // Not yet opened, but we completed all checks
    if (isKycVerified(data)) {
      return (
        <div className='flex flex-col py-8 justify-center items-center space-y-8'>
          <Spinner />
          <div className='text-gray-500 text-center'>
            Your account has passed verification and is pending opening
            <br />
            <br />
            Contact{' '}
            <span className='text-blue-700'>
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </span>{' '}
            for more information
          </div>
        </div>
      )
    }

    // KYC Denied; we have required actions
    const kycRequiredActions: string[] = getKycRequiredActions(data)
    if (kycRequiredActions.length > 0) {
      return (
        <div className='flex flex-col py-8 justify-center items-center space-y-8'>
          <Spinner />
          <div>
            <h2 className='text-center text-md mt-4'>
              Some fields need to be fixed. Please go back and fix them.
            </h2>

            <ul className='list-none text-center px-4'>
              {renderErrors(kycRequiredActions)}
            </ul>
          </div>

          <div>
            <span className='text-gray-300'>
              Please reach out to {supportEmail} to resolve the issue.
            </span>
          </div>
        </div>
      )
    }

    // No required actions; best we can do is tell the user to wait
    return (
      <div className='flex flex-col py-8 justify-center items-center space-y-8'>
        <Spinner />
        <div>
          <h2 className='text-gray-600 text-md mt-4'>
            Running background checks ⏳ This may take up to 5 minutes. You may
            leave to do other things while you wait, but please leave this page
            open on your browser
          </h2>
        </div>
      </div>
    )
  }

  const renderErrorScreen = () => {
    return (
      <div className='flex flex-col py-8 justify-center items-center space-y-8'>
        <Spinner />
        <div>
          Error contacting server. Please double-check your connection and
          contact customer support
        </div>
      </div>
    )
  }

  const renderLoading = () => {
    return (
      <div className='flex flex-col py-8 justify-center items-center space-y-8'>
        <Spinner />
        <div>Verifying KYC</div>
      </div>
    )
  }

  const render = () => {
    if (isLoading) {
      return renderLoading()
    } else if (error) {
      return renderErrorScreen()
    } else {
      return renderKYCAccountStatus()
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='px-4 space-y-4'>
        <Header
          name='KYC - Verification (5/5)'
          step={3}
          total={6}
          prev={prev}
        />
      </div>

      {render()}
    </div>
  )
}
