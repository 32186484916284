import useSWR from 'swr'
import api from 'lib/api-client'

export function useKYCAccountData(accountId: string) {
  const { data, error } = useSWR(
    `/api/kyc/account/${accountId}`,
    async () => api.getKYCAccount(accountId),
    { refreshInterval: 3000, revalidateIfStale: true }
  )

  return {
    data,
    isLoading: !error && !data,
    error: error
  }
}
