import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Header } from '../Header'

const imageMimeType = /image\/(png|jpg|jpeg|gif)/i
const acceptedFileTypes = ['png', 'jpg', 'jpeg', 'gif']

type ImageBlobSetter = Dispatch<SetStateAction<Blob | string>>

export const Documents: React.FC<{
  prev: (e) => void
  submit: (e) => void
  lastName: string
  accountId: string
  IDFront: Blob | string
  IDBack: Blob | string
  setFrontIDFile: ImageBlobSetter
  setBackIDFile: ImageBlobSetter
}> = ({ prev, submit, IDFront, IDBack, setFrontIDFile, setBackIDFile }) => {
  const [error, setError] = useState('')

  const [frontIdenticationImage, setFrontIdentificationImage] =
    useState<string>()
  const [backIdentificationImage, setBackIdentificationImage] =
    useState<string>()

  // Restore state if returning from another part of flow
  useEffect(() => {
    let frontFileReader: FileReader
    let backFileReader: FileReader
    let hasUserCancelled = false

    if (IDFront) {
      frontFileReader = new FileReader()

      frontFileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e?.target?.result) {
          const { result } = e.target

          if (result && !hasUserCancelled) {
            const url = result.toString()
            setFrontIdentificationImage(url)
          }
        }
      }

      frontFileReader.readAsDataURL(IDFront as Blob)
    }

    if (IDBack) {
      backFileReader = new FileReader()
      backFileReader.onload = (e) => {
        if (e?.target?.result) {
          const { result } = e.target

          if (result && !hasUserCancelled) {
            const url = result.toString()
            setBackIdentificationImage(url)
          }
        }
      }

      backFileReader.readAsDataURL(IDBack as Blob)
    }

    // cleanup file reader & don't allow it to run again
    return () => {
      hasUserCancelled = true

      if (frontFileReader?.readyState === 1) {
        frontFileReader.abort()
      }

      if (backFileReader?.readyState === 1) {
        backFileReader.abort()
      }
    }
  }, [IDFront, IDBack])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!(IDFront && IDBack)) {
      setError('Please fill out ALL fields')
    } else {
      submit(e)
    }
  }

  const fileUpload = (e) => {
    const file = e.target.files[0]
    const fileNameExtention = file?.name.split('.').pop()

    if (
      !file?.type?.match(imageMimeType) ||
      !acceptedFileTypes.includes(fileNameExtention)
    ) {
      alert(
        'Image type is not valid. Please only use files that end with jpg, png, and gif'
      )
      return
    }
    return setFrontIDFile(file)
  }

  const fileUpload2 = (e) => {
    const file = e.target.files[0]
    const fileNameExtention = file?.name.split('.').pop()

    if (
      !file?.type?.match(imageMimeType) ||
      !acceptedFileTypes.includes(fileNameExtention)
    ) {
      alert(
        'Image type is not valid. Please only use files that end with jpg, png, and gif'
      )
      return
    }
    return setBackIDFile(file)
  }

  return (
    <div className='flex flex-col'>
      <div className='px-4 space-y-4'>
        <Header name='KYC - Documents' step={3} total={6} prev={prev} />
        <div className='space-y-6'>
          <div className='px-2 sm:pt-4 sm:rounded-lg sm:p-6'>
            <h1 className='text-xl font-medium text-gray-900'>
              Upload document(s){' '}
            </h1>
            <p className='mt-1 text-sm text-gray-500'>
              Last step in KYC verification is to upload identification
              documents. Our banking partner runs automated checks, which should
              take no more than 5 minutes
            </p>

            <br />
            <form
              onSubmit={handleSubmit}
              onFocus={() => setError('')}
              className='space-y-6'
            >
              <div>
                <label
                  htmlFor='location'
                  className='block text-sm font-medium text-gray-700'
                >
                  Select Document Type
                </label>
                <select
                  id='location'
                  name='location'
                  disabled
                  className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                  defaultValue='driversLicense'
                >
                  <option>Drivers License</option>
                </select>
                <p className='text-gray-400	text-sm py-1'>
                  Only driver's license supported. Other documents coming soon!
                </p>
              </div>
              <br />
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  {' '}
                  ID Photo Front{' '}
                </label>
                <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                  {frontIdenticationImage ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img src={frontIdenticationImage} alt='preview' />
                  ) : (
                    <div className='space-y-1 text-center'>
                      <label
                        htmlFor='file-upload1'
                        className='relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                      >
                        <span>Upload a file</span>
                        <input
                          id='file-upload1'
                          name='file-upload1'
                          type='file'
                          className='sr-only'
                          onChange={fileUpload}
                        />
                      </label>
                      <p className='text-xs text-gray-500'>
                        PNG, JPG, GIF up to 2MB
                      </p>
                    </div>
                  )}
                </div>
                {frontIdenticationImage && (
                  <button
                    className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      setFrontIDFile('')
                      setFrontIdentificationImage(undefined)
                    }}
                  >
                    Remove file
                  </button>
                )}
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  {' '}
                  ID Photo Back{' '}
                </label>
                <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                  {backIdentificationImage ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img src={backIdentificationImage} alt='preview' />
                  ) : (
                    <div className='space-y-1 text-center'>
                      <label
                        htmlFor='file-upload2'
                        className='relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                      >
                        <span>Upload a file</span>
                        <input
                          id='file-upload2'
                          name='file-upload2'
                          type='file'
                          className='sr-only'
                          onChange={fileUpload2}
                        />
                      </label>
                      <p className='text-xs text-gray-500'>
                        PNG, JPG, GIF up to 2MB
                      </p>
                    </div>
                  )}
                </div>
                {backIdentificationImage && (
                  <button
                    className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      setBackIDFile('')
                      setBackIdentificationImage(undefined)
                    }}
                  >
                    Remove file
                  </button>
                )}
              </div>
              <div className='w-full'>
                <button
                  type='submit'
                  className='mt-4 rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer bg-indigo-500'
                >
                  Submit
                </button>
                {error && (
                  <p className='text-center	mt-1 text-sm text-red-500'>
                    {error}
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
