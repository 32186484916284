import React, { Dispatch, SetStateAction, useState } from 'react'
import { Header } from '../Header'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from '../../../styles/PhoneNumber.module.css'
import { isValid } from 'ssn-validator'
import validator from 'validator'
import { formDateToString } from 'lib/utils'

export const Profile: React.FC<{
  savedEmail: string
  prev: () => void
  submit: (e: React.FormEvent) => void
  firstName: string
  lastName: string
  dob: Date
  taxIdNumber: string
  phoneNumber: string
  setFirstName: Dispatch<SetStateAction<string>>
  setLastName: Dispatch<SetStateAction<string>>
  setDob: Dispatch<SetStateAction<Date>>
  setTaxIdNumber: Dispatch<SetStateAction<string>>
  setPhoneNumber: Dispatch<SetStateAction<string>>
}> = ({
  savedEmail,
  prev,
  submit,
  firstName,
  lastName,
  dob,
  taxIdNumber,
  phoneNumber,
  setFirstName,
  setLastName,
  setDob,
  setTaxIdNumber,
  setPhoneNumber
}) => {
  const [error, setError] = useState('')

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number)
    return isValidPhoneNumber
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!(firstName && lastName && dob && taxIdNumber)) {
      setError('Please fill out ALL fields')
    } else if (!isValid(taxIdNumber)) {
      setError('Social Security Number or Tax ID is invalid')
    } else if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number is invalid')
    } else {
      return submit(e)
    }
  }

  return (
    <div>
      <div className='px-4 sm:space-y-8'>
        <Header name='KYC - Profile' step={3} total={6} prev={prev} />

        <div className='px-4 py-5 sm:rounded-lg sm:p-6'>
          <form onSubmit={handleSubmit} onFocus={() => setError('')}>
            <div>
              <h3 className='text-xl leading-6 font-medium text-gray-900'>
                Personal Information
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                We need some more information from you to complete KYC
              </p>
            </div>
            <div className='sm:col-span-6 mt-2'>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  value={savedEmail}
                  disabled
                  className='appearance-none block w-full px-3 py-3 bg-gray-100 text-gray-500 font-medium border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>
            </div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-3'>
                <div className='mt-1'>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='first-name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      {' '}
                      First name{' '}
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='first-name'
                        id='first-name'
                        autoComplete='given-name'
                        placeholder='First Name'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='sm:col-span-3'>
                <div className='mt-1'>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      {' '}
                      Last name{' '}
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='last-name'
                        id='last-name'
                        autoComplete='family-name'
                        placeholder='Last Name'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='sm:col-span-6'>
                <label
                  htmlFor='phone-number'
                  className='block text-sm font-medium text-gray-700'
                >
                  Phone number
                </label>
                <div className='mt-1 relative rounded-md '>
                  <PhoneInput
                    country='us'
                    containerClass={styles.PhoneInputStyle}
                    inputClass={styles.PhoneInputInputStyle}
                    buttonClass={styles.PhoneCountryButton}
                    placeholder='Phone number'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(`+${e}`)}
                  />
                </div>
              </div>

              <div className='sm:col-span-3'>
                <p className='text-sm font-medium'>Date of birth</p>
                <div className='mt-1'>
                  <input
                    id='dob'
                    name='dob'
                    type='date'
                    min='1970-01-01'
                    max='2010-12-31'
                    value={formDateToString(dob)}
                    onChange={(e) => {
                      // value comes in as 'YYYY-MM-DD', and is
                      // blank on initial trigger
                      const value: string = e.currentTarget.value

                      if (value) {
                        const newDob = new Date(value)
                        setDob(newDob)
                      }
                    }}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>
              <div className='sm:col-span-3'>
                <p className='text-sm font-medium'>
                  Social Security Number or Tax ID
                </p>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='tax-id-number'
                    id='tax-id-number'
                    placeholder='SSN or Tax ID'
                    value={taxIdNumber}
                    onChange={(e) => setTaxIdNumber(e.target.value)}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>
            </div>
            <div className='w-full'>
              <button
                type='submit'
                className='mt-10 rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer bg-indigo-500'
              >
                Next
              </button>
              {error && (
                <p className='text-center	mt-1 text-sm text-red-500'>{error}</p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
