import React, { useEffect, useState } from 'react'

import api, { isApiClientError } from 'lib/api-client'

import {
  getKycRequiredActions,
  isAccountClosed,
  isAccountOpen,
  isKycVerified
} from 'lib/utils/primetrust'

import { Address } from './Address'
import { Agreement } from './Agreement'
import { Documents } from './Documents'
import { Profile } from './Profile'
import { Verification } from './Verification'
import { useCustomer } from 'lib/hooks/useCustomer'
import { ApiError, KYCError } from 'lib/types'
import { formDataToObject } from 'lib/utils'

export enum OrderDataStatus {
  Completed = 'COMPLETED'
}

export enum FormSteps {
  Profile,
  Address,
  Agreement,
  Documents,
  Verification
}

type UploadResponse = {
  url: string
  key: string
}

export type InquiryFields = {
  nameFirst?: string
  nameLast?: string
  birthdate?: string
  street?: string
  city?: string
  province?: string
  postalCode?: string
  countryCode?: string
  phoneNumber?: string
  emailAddress?: string
}

export type KYCFields = {
  email: string
  IDFront: File
  IDBack: File
}

export const KYCErrorsList: React.FC<{ errors: KYCError[] }> = ({ errors }) => {
  return (
    <ul className='list-none text-red-500 text-sm'>
      {errors.map((err, i) => {
        return (
          <li key={i}>
            {err.title} - {err.detail}
          </li>
        )
      })}
    </ul>
  )
}

export const KYC: React.FC<{
  savedEmail: string
  prev: () => void
  submit: () => void
  setLegalName: React.Dispatch<React.SetStateAction<string>>
}> = ({ savedEmail, prev, submit, setLegalName }) => {
  const { data, isLoading } = useCustomer()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [dob, setDob] = useState(new Date('2000-01-01'))
  const [residenceCountry] = useState('US')
  const [taxIdNumber, setTaxIdNumber] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [apartmentNumber, setApartmentNumber] = useState('')
  const [city, setCity] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [region, setRegion] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('US')

  const [IDFront, setIDFront] = useState<Blob | string>('')
  const [IDBack, setIDBack] = useState<Blob | string>('')
  const [accountId, setAccountId] = useState('')
  const [agreement, setAgreement] = useState('')
  const [kycErrors, setKYCErrors] = useState<KYCError[]>([])
  const [uploadError, setUploadError] = useState('')

  const [formStep, setFormStep] = useState<FormSteps>(FormSteps.Profile)

  const formatUserFormBody = () => {
    const formData = new FormData()
    formData.append('first-name', firstName)
    formData.append('last-name', lastName)
    formData.append('email', savedEmail)
    formData.append('tax-id-number', taxIdNumber)
    formData.append('tax-country', residenceCountry)
    formData.append('tax-state', region)
    formData.append('date-of-birth', dob.toISOString().slice(0, 10))
    formData.append('country', countryCode)
    formData.append('number', phoneNumber)
    // Primetrust strongly suggests apartment number
    // is included in the address for KYC
    formData.append('street1', combineAddressAndApartmentNumber())
    formData.append('street2', '')
    formData.append('postal-code', postalCode)
    formData.append('city', city)
    formData.append('region', region)
    formData.append('country', countryCode)
    return formData
  }

  const combineAddressAndApartmentNumber = () => {
    return apartmentNumber ? `${address} ${apartmentNumber}` : address
  }

  const createAccountandAgreement = async () => {
    const formData = formatUserFormBody()
    const body = formDataToObject(formData)

    try {
      const res = await api.createKYCAccount(body)
      const { accountId, agreement } = res
      setAccountId(accountId)
      setAgreement(agreement)

      return true
    } catch (e) {
      const error = e as { message: string }

      if (isApiClientError(error)) {
        try {
          const payload: ApiError<{ errors: KYCError[] }> =
            await error.response.json()

          if (payload.data?.errors) {
            setKYCErrors(payload.data.errors)
          }
        } catch (e) {
          setKYCErrors([])
        }
      }

      return false
    }
  }

  const uploadPhoto = async (file: File): Promise<UploadResponse> => {
    const filename = encodeURIComponent(file.name)
    const filetype = encodeURIComponent(file.type)

    const res = await fetch(
      `/api/uploadUrl?filename=${filename}&filetype=${filetype}`
    )
    const {
      url,
      fields
    }: { url: string; fields: { key: string; 'Content-Type': string } } =
      await res.json()
    const formData = new FormData()

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value as string)
    })

    const upload = await fetch(url, {
      method: 'POST',
      body: formData
    })

    if (upload.ok) {
      console.log('Uploaded successfully!')
    } else {
      console.error('Upload failed.')
    }

    return { url, key: fields.key }
  }

  /**
   * The big step. Handle uploads to S3. On failure,
   * don't advance the user
   */
  const uploadKYC = async () => {
    let frontRes: UploadResponse
    let backRes: UploadResponse

    try {
      frontRes = await uploadPhoto(IDFront as File)
      backRes = await uploadPhoto(IDBack as File)
    } catch (error) {
      setUploadError('Failed to upload, please reset your front ID')
      throw error
    }

    try {
      const formData = formatUserFormBody()
      formData.append('accountId', accountId)
      formData.append('frontImgUrl', frontRes.url)
      formData.append('frontImgKey', frontRes.key)
      formData.append('backImgUrl', backRes.url)
      formData.append('backImgKey', backRes.key)

      await api.uploadKYCDocument(formData)
    } catch (error) {
      if (isApiClientError(error)) {
        const apiError: ApiError<KYCError> = await error.response.json()
        if (apiError?.data) {
          setKYCErrors([apiError.data])
        }
      } else {
        setUploadError(
          `We've encountered an error, and notified our staff. Please try again in a few minutes.`
        )
      }

      throw error
    }
  }

  const clearErrors = () => {
    setUploadError('')
    setKYCErrors([])
  }

  const handlePrev = () => {
    clearErrors()

    switch (formStep) {
      case FormSteps.Profile:
        prev()
        break
      case FormSteps.Address:
        setFormStep(FormSteps.Profile)
        break
      case FormSteps.Agreement:
        setFormStep(FormSteps.Address)
        break
      case FormSteps.Documents:
        setFormStep(FormSteps.Agreement)
        break
      case FormSteps.Verification:
        setFormStep(FormSteps.Documents)
        break
    }
  }

  const handleSubmit = () => {
    clearErrors()

    switch (formStep) {
      case FormSteps.Profile:
        setFormStep(FormSteps.Address)
        break
      case FormSteps.Address:
        createAccountandAgreement()
        setFormStep(FormSteps.Agreement)
        break
      case FormSteps.Agreement:
        setFormStep(FormSteps.Documents)
        break
      case FormSteps.Documents:
        // TODO why are we setting the legal name here
        // as opposed to earlier in the flow?
        setLegalName(`${firstName} ${lastName}`)
        uploadKYC()
          .then(() => {
            setFormStep(FormSteps.Verification)
          })
          .catch((e) => {
            console.log(e)
          })
        break
    }
  }

  // auto-advance if partial or fully kyc
  useEffect(() => {
    async function skipOnPartialKYC() {
      if (data && !isLoading) {
        const { customer } = data
        if (customer && customer.ptAccountId) {
          const kycAccountData = await api.getKYCAccount(customer.ptAccountId)

          // NB skip if account is open
          //
          // NB timing. possible that webhook fired way later
          // than the update to the accounts model
          if (isAccountOpen(kycAccountData)) {
            submit()
            return
          }

          // NB skip if account is pending opening
          if (isKycVerified(kycAccountData)) {
            // TODO move this setter away from here
            // this rly shouldn't be set this late
            setAccountId(customer.ptAccountId)
            setFormStep(FormSteps.Verification)
          }

          // NB if account has been closed, do not ask user
          // to enter information again, and do not allow
          // them to advance
          if (isAccountClosed(kycAccountData)) {
            setAccountId(customer.ptAccountId)
            setFormStep(FormSteps.Verification)
          }

          // NB if user has initiated KYC and has required
          // checks, ask them to go through document verification
          if (getKycRequiredActions(kycAccountData).length > 0) {
            setAccountId(customer.ptAccountId)
            setFormStep(FormSteps.Documents)
            // TODO document verification screen
          }
        }
      }
    }

    skipOnPartialKYC()
  }, [data, isLoading])

  return (
    <div>
      {formStep === FormSteps.Profile && (
        <>
          <Profile
            savedEmail={savedEmail}
            prev={handlePrev}
            submit={handleSubmit}
            firstName={firstName}
            lastName={lastName}
            dob={dob}
            taxIdNumber={taxIdNumber}
            phoneNumber={phoneNumber}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setDob={setDob}
            setTaxIdNumber={setTaxIdNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </>
      )}

      {formStep === FormSteps.Address && (
        <>
          <Address
            prev={handlePrev}
            submit={handleSubmit}
            apartmentNumber={apartmentNumber}
            addressStreet={address}
            addressCity={city}
            addressRegion={region}
            addressPostalCode={postalCode}
            addressCountryCode={countryCode}
            setApartmentNumber={setApartmentNumber}
            setAddress={setAddress}
            setCity={setCity}
            setRegion={setRegion}
            setPostalCode={setPostalCode}
            setCountryCode={setCountryCode}
          />
        </>
      )}

      {formStep === FormSteps.Agreement && (
        <>
          <Agreement
            prev={handlePrev}
            submit={handleSubmit}
            content={agreement}
          />
        </>
      )}

      {formStep === FormSteps.Documents && (
        <>
          <Documents
            lastName={lastName}
            accountId={accountId}
            prev={handlePrev}
            submit={handleSubmit}
            IDFront={IDFront}
            IDBack={IDBack}
            setFrontIDFile={setIDFront}
            setBackIDFile={setIDBack}
          />
        </>
      )}

      {formStep === FormSteps.Verification && (
        <>
          <Verification
            accountId={accountId}
            prev={handlePrev}
            submit={submit}
          />
        </>
      )}

      {uploadError && (
        <div className='mt-6 mx-8 px-4 py-3 border-2 border-red-300 bg-red-50'>
          <p className='text-red-900'>{uploadError}</p>
        </div>
      )}

      {kycErrors.length > 0 && (
        <div className='mt-6 mx-8 px-4 py-3 border-2 border-red-300 bg-red-50'>
          <KYCErrorsList errors={kycErrors} />
        </div>
      )}
    </div>
  )
}
