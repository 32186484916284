import React from 'react'
import Image from 'next/image'

import { Tooltip } from '@material-tailwind/react'

export const Navbar: React.FC<{ email: string }> = ({ email }) => {
  return (
    <div className='flex flex-row px-4 sm:px-10 pt-4 sm:py-4 items-center space-x-2'>
      <div className='pt-2 text-3xl'>
        <Image src='/supercharge_logo.svg' width={40} height={40} />
      </div>

      <div className='text-2xl font-bold text-gray-900 z-10'>
        Super<span className='text-indigo-700'>charge</span>
      </div>

      <div className='flex-grow'></div>

      <div className='flex-row-reverse z-10'>
        <h4 className='text-gray-400 text-xs cursor-pointer'>
          {email && (
            <Tooltip
              content={`Logged in as ${email}`}
              placement='bottom'
              className='text-xs w-54 bg-gray-900 text-gray-400'
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 }
              }}
            >
              <span className='inline-flex items-center justify-center h-6 w-6 rounded-full bg-indigo-500'>
                <span className='text-xs font-medium leading-none text-white'>
                  {email.charAt(0).toUpperCase()}
                </span>
              </span>
            </Tooltip>
          )}
        </h4>
      </div>
    </div>
  )
}
