import React, { Dispatch, SetStateAction, useState } from 'react'
import { Header } from '../Header'

export const Address: React.FC<{
  prev: () => void
  submit: () => void
  apartmentNumber: string
  addressStreet: string
  addressCity: string
  addressRegion: string
  addressPostalCode: string
  addressCountryCode: string
  setApartmentNumber: Dispatch<SetStateAction<string>>
  setAddress: Dispatch<SetStateAction<string>>
  setCity: Dispatch<SetStateAction<string>>
  setRegion: Dispatch<SetStateAction<string>>
  setPostalCode: Dispatch<SetStateAction<string>>
  setCountryCode: Dispatch<SetStateAction<string>>
}> = ({
  prev,
  submit,
  apartmentNumber,
  addressStreet,
  addressCity,
  addressRegion,
  addressPostalCode,
  addressCountryCode,
  setApartmentNumber,
  setAddress,
  setCity,
  setRegion,
  setPostalCode
}) => {
  const [error, setError] = useState('')

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (
      !(
        addressCity &&
        addressStreet &&
        addressPostalCode &&
        addressRegion &&
        addressCountryCode
      )
    ) {
      setError('Please fill out ALL fields')
      return
    }

    submit()
  }

  return (
    <div>
      <div className='px-4 sm:space-y-8'>
        <Header name='KYC - Address' step={3} total={6} prev={prev} />

        <div className='px-4 py-5 sm:rounded-lg sm:p-6'>
          <form onSubmit={handleSubmit} onFocus={() => setError('')}>
            <div>
              <h1 className='text-xl font-medium text-gray-900'>
                Add Location Data (primary residency)
              </h1>
              <div className='px-6 py-4 my-8 rounded-md text-sm font-base text-gray-500  bg-blue-50'>
                🏠{' '}
                <span className='text-base font-bold'>
                  Specific address required
                </span>
                <br />
                <p className='pl-4 px-4 py-2'>
                  Be sure to include your full address, including apartment
                  number (if applicable). Our KYC verification process requires
                  full detail or it may reject you from opening an account
                </p>
              </div>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <p className='text-sm font-medium'>Country</p>
                <div className='mt-1'>
                  <select
                    id='country'
                    name='country'
                    disabled
                    value={'US'}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  >
                    <option value=''>Country</option>
                    <option value='US'>United States</option>
                  </select>
                  <p className='text-gray-400	text-sm py-1'>
                    Only U.S. supported. Other regions coming soon!
                  </p>
                </div>
              </div>

              <div className='sm:col-span-4'>
                <div className='mt-1'>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    {' '}
                    Street{' '}
                  </label>
                  <input
                    type='text'
                    name='street-address'
                    id='street-address'
                    autoComplete='street-address'
                    placeholder='Street Address'
                    value={addressStreet}
                    onChange={(e) => setAddress(e.target.value)}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div className='sm:col-span-2'>
                <div className='mt-1'>
                  <label
                    htmlFor='apartmentNumber'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Apt / Unit
                  </label>
                  <input
                    type='text'
                    name='apartmentNumber'
                    id='apartmentNumber'
                    placeholder='14H'
                    autoComplete='apartment-number'
                    value={apartmentNumber}
                    onChange={(e) => setApartmentNumber(e.target.value)}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div className='sm:col-span-2'>
                <div className='mt-1'>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium text-gray-700'
                  >
                    {' '}
                    City{' '}
                  </label>
                  <input
                    type='text'
                    name='city'
                    id='city'
                    placeholder='City'
                    autoComplete='address-level2'
                    value={addressCity}
                    onChange={(e) => setCity(e.target.value)}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div className='sm:col-span-2'>
                <div className='mt-1'>
                  <div>
                    <label
                      htmlFor='region'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Region
                    </label>
                    <select
                      id='region'
                      name='region'
                      onChange={(e) => setRegion(e.target.value)}
                      className='block w-full pl-3 pr-10 text-base py-3 border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                    >
                      <option value=''></option>
                      <option value='AL'>Alabama</option>
                      <option value='AK'>Alaska</option>
                      <option value='AZ'>Arizona</option>
                      <option value='AR'>Arkansas</option>
                      <option value='CA'>California</option>
                      <option value='CO'>Colorado</option>
                      <option value='CT'>Connecticut</option>
                      <option value='DE'>Delaware</option>
                      <option value='DC'>District Of Columbia</option>
                      <option value='FL'>Florida</option>
                      <option value='GA'>Georgia</option>
                      <option value='HI'>Hawaii</option>
                      <option value='ID'>Idaho</option>
                      <option value='IL'>Illinois</option>
                      <option value='IN'>Indiana</option>
                      <option value='IA'>Iowa</option>
                      <option value='KS'>Kansas</option>
                      <option value='KY'>Kentucky</option>
                      <option value='LA'>Louisiana</option>
                      <option value='ME'>Maine</option>
                      <option value='MD'>Maryland</option>
                      <option value='MA'>Massachusetts</option>
                      <option value='MI'>Michigan</option>
                      <option value='MN'>Minnesota</option>
                      <option value='MS'>Mississippi</option>
                      <option value='MO'>Missouri</option>
                      <option value='MT'>Montana</option>
                      <option value='NE'>Nebraska</option>
                      <option value='NV'>Nevada</option>
                      <option value='NH'>New Hampshire</option>
                      <option value='NJ'>New Jersey</option>
                      <option value='NM'>New Mexico</option>
                      <option value='NY'>New York</option>
                      <option value='NC'>North Carolina</option>
                      <option value='ND'>North Dakota</option>
                      <option value='OH'>Ohio</option>
                      <option value='OK'>Oklahoma</option>
                      <option value='OR'>Oregon</option>
                      <option value='PA'>Pennsylvania</option>
                      <option value='RI'>Rhode Island</option>
                      <option value='SC'>South Carolina</option>
                      <option value='SD'>South Dakota</option>
                      <option value='TN'>Tennessee</option>
                      <option value='TX'>Texas</option>
                      <option value='UT'>Utah</option>
                      <option value='VT'>Vermont</option>
                      <option value='VA'>Virginia</option>
                      <option value='WA'>Washington</option>
                      <option value='WV'>West Virginia</option>
                      <option value='WI'>Wisconsin</option>
                      <option value='WY'>Wyoming</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='sm:col-span-2'>
                <div className='mt-1'>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    {' '}
                    Postal Code{' '}
                  </label>
                  <input
                    type='text'
                    name='postal-code'
                    id='postal-code'
                    autoComplete='postal-code'
                    placeholder='Postal Code'
                    value={addressPostalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    className='appearance-none block w-full px-3 py-3 border-2 border-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>
            </div>
            <div className='w-full'>
              <button
                type='submit'
                className='mt-10 rounded-lg font-bold text-white text-lg px-4 py-3 border-0 w-full cursor-pointer bg-indigo-500'
              >
                Next
              </button>
              {error && (
                <p className='text-center	mt-1 text-sm text-red-500'>{error}</p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
