// /* This example requires Tailwind CSS v2.0+ */
// import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { CheckIcon } from '@heroicons/react/outline'

import React, { Fragment } from 'react'
import { motion } from 'framer-motion'
import { Paper } from 'components/Paper'
import Image from 'next/image'

export const PatriotModal: React.FC<{
  showPatriotModal: boolean
  setShowPatriotModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ showPatriotModal, setShowPatriotModal }) => {
  return (
    <Transition.Root show={showPatriotModal} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setShowPatriotModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel>
                <Paper>
                  <div className='relative z-20'>
                    <header className='py-10'>
                      <div className='max-w-7xl mx-auto px-24 space-y-6'>
                        <motion.div
                          animate={{ opacity: [0, 1] }}
                          transition={{ duration: 1 }}
                        >
                          <div className='relative px-4 sm:px-6 lg:px-8'>
                            <div className='text-lg max-w-prose mx-auto'>
                              <div className='text-lg max-w-prose mx-auto'>
                                <h1>
                                  <span className='block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase'>
                                    SUPERCHARGE
                                  </span>
                                  <span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                                    Patriot Act
                                  </span>
                                </h1>
                              </div>
                              <br />
                              <h4>
                                <span className='mt-2 block text-xl leading-8 tracking-tight fold-extrabold text-gray-900 sm:text-xl'>
                                  IMPORTANT INFORMATION ABOUT PROCEDURES FOR
                                  OPENING A NEW ACCOUNT
                                </span>
                              </h4>
                              <p className='mt-8 text-xl text-gray-500 leading-8'>
                                To help the government fight the funding of
                                terrorism and money laundering activities,
                                federal law requires all financial institutions
                                to obtain, verify, and record information that
                                identifies each person who opens an account.
                                What this means for you: When you open an
                                account, we will ask for your name, address,
                                date of birth, and other information that will
                                allow us to identify you. We will require your
                                driver’s license or other identifying
                                documentation.
                              </p>
                              <br />
                              <div className='flex justify-center flex-row'>
                                <button
                                  onClick={() => setShowPatriotModal(false)}
                                  className='mt-4 rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-4/5 cursor-pointer bg-indigo-500'
                                >
                                  Continue
                                </button>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      </div>
                    </header>
                  </div>

                  <style global jsx>{`
                    body > div:first-child,
                    div#__next,
                    div#__next > div {
                      height: 100%;
                    }
                  `}</style>
                </Paper>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
