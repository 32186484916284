import React, { useState, useEffect } from 'react'
import cs from 'classnames'

import { CashIcon } from '@heroicons/react/solid'

import { Header } from '../Header'
import { PlaidComponent } from './PlaidComponent'
import api from 'lib/api-client'
import { AchAccount } from 'lib/types'

// Page-level component for payments
export const ChooseAch: React.FC<{
  savedEmail: string
  prev: () => void
  submit: (fundTransferMethodId: string) => void
  amount: number
  setAccountId: React.Dispatch<React.SetStateAction<string>>
}> = ({ savedEmail, prev, submit, amount, setAccountId }) => {
  const [createAch, setCreateAch] = useState(false)
  const [availableAchAccounts, setAvailableAchAccounts] = useState(
    [] as AchAccount[]
  )
  const [isLoading, setLoading] = useState(true)

  const doneCreatingAch = (success: boolean) => {
    if (success) {
      setCreateAch(false)
    }
  }

  // Use same icon for all banks first
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getBankLogo = (bankName: string) => {
    return <CashIcon className='h-11 w-11 align-middle' aria-hidden='true' />
  }

  useEffect(() => {
    async function getAvailableAchAccounts() {
      const achAccounts = await api.getAchFundsTransferMethods()
      setAvailableAchAccounts(achAccounts)
      setLoading(false)
    }
    getAvailableAchAccounts()
  }, [createAch])

  const render = () => {
    if (createAch) {
      return (
        <>
          <PlaidComponent
            savedEmail={savedEmail}
            prev={prev}
            submit={doneCreatingAch}
            setAccountId={setAccountId}
            amount={amount}
          />
        </>
      )
    } else {
      return (
        <div>
          {isLoading ? (
            <div className='flex flex-col justify-center space-y-6 py-10'>
              <div className='text-center font-md text-indigo-700'>
                Fetching existing ACH accounts
              </div>
              <div
                className={cs(
                  'spinner-border self-center',
                  'align-middle content-center',
                  'w-10 h-10',
                  'border-4 text-indigo-700 border-solid',
                  'rounded-full animate-spin'
                )}
              />
            </div>
          ) : (
            <ul role='list'>
              {availableAchAccounts.length === 0 && (
                <div className='text-gray-700 pb-12'>
                  <h3 className='text-xl leading-6 font-medium text-gray-900'>
                    Add an ACH account
                  </h3>
                  <p className='mt-1 text-md text-gray-500'>
                    You don't seem to have any registered accounts with us. Add
                    one below 👇
                  </p>
                </div>
              )}

              {availableAchAccounts.map(
                ({
                  bankAccountType,
                  bankName,
                  lastFourDigits,
                  fundTransferMethodId
                }) => (
                  <li
                    key={fundTransferMethodId}
                    className='px-16 py-4'
                    onClick={() => submit(fundTransferMethodId)}
                  >
                    <div
                      className={cs(
                        'cursor-pointer px-3 py-4 rounded-lg text-black bg-white border-2 focus:shadow-outline hover:bg-gray-100'
                      )}
                    >
                      <div className='flex flex-row justify-between mx-2'>
                        {getBankLogo(bankName)}
                        <div className='flex flex-col pl-6'>
                          <div className='text-lg font-bold'>
                            **** {lastFourDigits}
                          </div>
                          <div className='text-sm text-gray-400 text-right'>
                            {bankName} {bankAccountType}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}

          <div className='w-full flex flex-col items-center py-4'>
            <button
              id='createCard'
              type='button'
              onClick={() => setCreateAch(true)}
              className={cs(
                'w-12 h-12 text-2xl text-white transition-colors duration-500 bg-indigo-500 rounded-full focus:shadow-outline',
                availableAchAccounts.length === 0 &&
                  !isLoading &&
                  'animate-bounce'
              )}
            >
              +
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className='overflow-hidden rounded-md'>
      <div className='space-y-4'>
        <Header name='Select ACH Account' step={5} total={6} prev={prev} />
        <div className='px-4 py-5'>{render()}</div>
      </div>
    </div>
  )
}

export default ChooseAch
