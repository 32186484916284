import React, { Fragment, useState, useContext } from 'react'
import cs from 'classnames'
import { Header } from './Header'

import { decodeAddress, encodeAddress } from '@polkadot/keyring'
import { hexToU8a, isHex } from '@polkadot/util'

import Identicon from 'react-identicons'

import { Dialog, Transition } from '@headlessui/react'

import PolkadotContext, { InjectedAccountWithMeta } from 'lib/contexts/polkadot'

import { desktop, getShortAddress } from 'lib/utils'
import { NewlyCreatedAccount } from 'lib/utils/createPolkadotAccount'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import api from 'lib/api-client'
import { DuplicateIcon } from '@heroicons/react/solid'
import { SwitchVerticalIcon } from '@heroicons/react/outline'

const isValidAddressPolkadotAddress = (address: string) => {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address))

    return true
  } catch (error) {
    return false
  }
}

export const ConnectWallet: React.FC<{
  prev: () => void
  submit: (walletAddress: string) => void
}> = ({ prev, submit }) => {
  const [walletAddress, setWalletAddress] = useState('')
  const [hasConnectedWallet, setHasConnectedWallet] = useState(false)
  const canContinue =
    walletAddress.length > 0 && isValidAddressPolkadotAddress(walletAddress)

  const onNext = () => {
    if (canContinue) {
      submit(walletAddress)
    }
  }

  const onSetWalletAddress = (walletAddress: string) => {
    setWalletAddress(walletAddress)
    setHasConnectedWallet(true)
  }

  return (
    <div className='flex flex-col space-y-4'>
      <Header name='Enter Destination' step={4} total={6} prev={prev} />

      <WalletInput
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
        disabled={hasConnectedWallet}
      />

      <div className='px-4'>
        <InnerWallet
          walletAddress={walletAddress}
          onSetWalletAddress={onSetWalletAddress}
        />

        <div className='flex flex-row pt-10'>
          <button
            onClick={onNext}
            className={cs(
              canContinue ? 'bg-indigo-500' : 'bg-indigo-200',
              'rounded-lg font-bold text-white text-lg px-5 py-4 border-0 w-full cursor-pointer'
            )}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

const WalletInput: React.FC<{
  walletAddress: string
  setWalletAddress: (token: string) => void
  disabled: boolean
}> = ({ walletAddress, setWalletAddress, disabled }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      const trimmedAddress = e.currentTarget.value.trim()
      setWalletAddress(trimmedAddress)
    }
  }

  return (
    <div className='rounded-md px-4 py-4 text-lg cursor-pointer'>
      <div className='text-md'>
        <div className='w-full'>
          <label
            className={cs(
              'block text-md font-medium text-gray-700 text-uppercase w-full',
              desktop
            )}
          >
            Add Receiving Wallet
          </label>

          <h3 className='text-base py-2 text-gray-500'>
            Enter your Polkadot address
          </h3>

          <div className='mt-1'>
            <input
              value={walletAddress}
              disabled={disabled}
              onChange={onChange}
              className={cs(
                'block px-3 py-3 w-full text-base border-2 border-gray-300 rounded-md bg-transparent h-16 sm:h-12 focus:outline-none',
                disabled && 'text-gray-400'
              )}
              placeholder='Enter wallet address'
            ></input>
          </div>
        </div>
      </div>
    </div>
  )
}

const InnerWallet: React.FC<{
  walletAddress: string
  onSetWalletAddress: (address: string) => void
}> = ({ walletAddress, onSetWalletAddress }) => {
  // const [parachain, setParachain] = useState<Parachains>(Parachains.Acala)
  const [showModal, setShowModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { enabled, accounts, active, connect, setActive } =
    useContext(PolkadotContext)

  // note: connect wallet is not forced; user can specify adddress
  const onConnect = () => {
    if (!enabled) connect()
    else setShowModal(true)
  }

  // callback for child modal
  const onSetActive = (account: InjectedAccountWithMeta) => {
    setActive(account)
    // bubble up to parent
    onSetWalletAddress(account.address)
  }

  // callback for create modal
  const onCreate = () => {
    setShowCreateModal(true)
  }

  // if enabled, force user to choosse wallet
  React.useEffect(() => {
    if (enabled && !active) {
      setShowModal(true)
    }
    if (active) {
      onSetWalletAddress(active.address)
    }
  }, [enabled, active, accounts])

  return (
    <div>
      {showModal && (
        <Modal
          accounts={accounts}
          showModal={showModal}
          setShowModal={setShowModal}
          onSetActive={onSetActive}
        />
      )}

      {showCreateModal && (
        <CreateModal
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
          onSetWalletAddress={onSetWalletAddress}
        />
      )}

      <div className='space-y-4'>
        {enabled && active && (
          <h3
            className='font-medium'
            onClick={() => {
              setShowModal(true)
            }}
          >
            <span className='hidden sm:inline-block px-2'>
              👉 Destination is{' '}
            </span>
            <span className={cs('rounded-xl px-2 py-0.5 font-mono')}>
              {active.meta.name || getShortAddress(active.address)}
            </span>
          </h3>
        )}

        {!enabled && (
          <div className='flex justify-center text-2xl'>
            <div>
              <SwitchVerticalIcon className='h-6 w-6 font-bold text-indigo-900' />
            </div>
          </div>
        )}

        {!(enabled && active) && (
          <div className='flex flex-col space-y-4'>
            <a
              className='text-gray-500 cursor-pointer hover:text-indigo-500'
              onClick={() => !walletAddress && onCreate()}
            >
              Don't have a Polkadot wallet? Click to create one
            </a>
          </div>
        )}

        <button
          onClick={onConnect}
          className={cs(
            'w-full px-3 py-3 font-medium rounded-md cursor-pointer h-16 text-gray-500',
            enabled ? 'border-0 bg-gray-100' : 'border-2 border-gray-300'
          )}
        >
          {!enabled ? 'Connect Polkadot.js' : 'Change Account'}
        </button>
      </div>
    </div>
  )
}

/* This example requires Tailwind CSS v2.0+ */
const AccountRow: React.FC<{
  account: InjectedAccountWithMeta
}> = ({ account }) => {
  return (
    <a href='#' className='flex-shrink-0 group block'>
      <div className='flex items-center'>
        <div>
          <div className='mt-4 inline-block h-9 w-9 rounded-full'>
            <Identicon size='32' string={account.address} />
          </div>
        </div>
        <div className='ml-4'>
          <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
            {account.meta.name}{' '}
          </p>
          <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700'>
            {getShortAddress(account.address)}
          </p>
        </div>
      </div>
    </a>
  )
}

const Modal: React.FC<{
  accounts: InjectedAccountWithMeta[]
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  onSetActive: (account: InjectedAccountWithMeta) => void
}> = ({ accounts, showModal, setShowModal, onSetActive }) => {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-50 inset-0 overflow-y-auto'
        onClose={setShowModal}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='relative inline-block align-bottom bg-white rounded-lg pt-6 pb-4 px-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full'>
              <h1 className='text-lg'>Choose Account</h1>
              <div>
                <ul role='list' className='divide-y divide-gray-200'>
                  {accounts.map((account, index) => (
                    <li
                      key={index}
                      className='py-4'
                      onClick={() => {
                        onSetActive(account)
                        setShowModal(false)
                      }}
                    >
                      <AccountRow account={account} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const CreateModal: React.FC<{
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  onSetWalletAddress: (account: string) => void
}> = ({ showModal, setShowModal, onSetWalletAddress }) => {
  const [account, setAccount] = React.useState<NewlyCreatedAccount>()
  const [copied, setCopied] = React.useState(false)

  // when component mounts, create a new account
  React.useEffect(() => {
    async function fetch() {
      const res = await api.createNonCustodial()
      setAccount(res)
      onSetWalletAddress(res.address)
    }

    fetch()
  }, [])

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-50 inset-0 overflow-y-auto'
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='relative inline-block align-bottom bg-white rounded-lg pt-6 pb-4 px-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-lg sm:w-full'>
              <div>
                {account ? (
                  <div>
                    <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                      <Identicon size='32' string={account.address} />
                    </div>

                    <div className='mt-3 sm:mt-5 space-y-6'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg text-center leading-6 font-medium text-gray-900'
                      >
                        Account Created
                      </Dialog.Title>

                      <div className='px-4 py-3 border border-gray-200'>
                        <p className='text-sm font-medium font-mono'>
                          {account.address}
                        </p>
                      </div>

                      <p
                        className={cs(
                          'px-4 py-3 rounded-md text-sm text-white font-bold',
                          copied ? 'bg-gray-300' : 'bg-red-500'
                        )}
                      >
                        Save this 12-word private key somewhere safe. It's your
                        responsibility to manage. If you lose this, your funds
                        may be lost forever.
                        <br />
                        <br />
                        We'll email you instructions on how to access your
                        wallet.
                      </p>

                      <CopyToClipboard
                        text={account.mnemonic}
                        onCopy={() => setCopied(true)}
                      >
                        <div className='flex flex-row items-center mt-4 px-4 py-3 border text-indigo-500 border-gray-200 bg-gray-50 font-mono cursor-pointer'>
                          <div className=''>{account.mnemonic}</div>
                          <div>
                            <DuplicateIcon className='h-4 w-4' />
                          </div>
                        </div>
                      </CopyToClipboard>

                      <div>
                        <button
                          className={cs(
                            'w-full px-3 py-3 font-medium rounded-md cursor-pointer text-white',
                            copied ? 'bg-indigo-500' : 'bg-red-700'
                          )}
                          onClick={() => copied && setShowModal(false)}
                        >
                          I've saved the passphrase
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
